<template>
  <div id="localities">
    <app-navbar></app-navbar>
    <div class="crossBallone">
      <img class="img-fluid" src="@/assets/crossRedBallone.png" alt="whiteBallone">
    </div>
    <h2 class="text-center">Lokality</h2>
    <div class="container">
      <div class="col-lg-10 offset-lg-1">
        <h3>Festival se koná napříč centrem Brna na více než 30 místech,
          a to jak vnitřní koncertní sály, tak i venkovní prostranství a náměstí. Pojďte se na ně podívat a poznat
          jejich
          příběhy i interprety, kteří v nich vystoupí</h3>
      </div>
      <div class="col-lg-10 offset-lg-1">
        <div v-for="locality in localities" v-bind:key="locality.id" class="row mb-lg-4 no-gutters localities-box">
          <div class="col-lg-4 img-hover-zoom">
            <img class="img-fluid cropped-img" :src="getImageUrl(locality.id)" alt="Foto">
          </div>
          <div class="col-lg-6 padding">
            <h4>{{ locality.name | translation }}</h4>
            <p class="crop-text">{{locality.description | translation }}</p>
            <div>
              <a v-on:click="goToProgram(locality.id)">
                <div type="button" class="btn">Program</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import navbar from "@/components/base/navbar";
import footer from "@/components/base/footer";
import axios from "@/api/axiom-instance";
import config from "@/config";

export default {
  name: "Localities",
  components: {
    'app-navbar': navbar,
    'app-footer': footer
  },
  data() {
    return {
      localities: []
    }
  },
  created() {
    this.load()
  },
  methods: {
    load: function () {
      axios
          .get("/stage/list/visible")
          .then((success) => {
            this.localities = success.data
          }).catch((failed) => {
        console.log(failed)
      })
    },
    getImageUrl: function (id) {
      return config.API_URL + "/image/preview?type=localities&name=" + id
    },
    goToProgram: function (id) {
      console.log(id)
      this.$router.push("/program")
      // TODO redirect to program
    }
  }

}
</script>

<style scoped>
#localities {
  background-color: #f3ba53;
}

h2 {
  padding-top: 4em;
  padding-bottom: 1em;
  font-weight: bold;
  font-size: 2.5em;
  color: #152326;
  font-family: "Transcript Pro";
}

h3 {
  padding-bottom: 2em;
  font-family: "Transcript Pro";
  font-size: 1.5em;
  color: #152326;
}

h4 {
  font-family: "Transcript Pro";
  font-size: 1.8em;
}

p {
  font-family: "Transcript Mono";
}

.localities-box {
  background-color: #eae7d6;
}

.cropped-img {
  height: 100%;
  width: 100%;
  padding-right: 2rem;
  object-fit: cover;
}

.btn {
  padding: 4px 12px;
  border-radius: 0;
  color: #152326;
  font-family: "Transcript Pro";
  background-color: #f3ba53;
}

.padding {
  padding-top: .8em;
  padding-bottom: .8em;
}

.crossBallone {
  width: 11%;
  position: absolute;
  top: 25vh;
  left: 10%;
}

.btn:hover {
  background-color: #152326;
  color: #eae7d6;
}
</style>
